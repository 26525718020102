import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { atom, useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useMetadata } from "src/contexts/metadata";
import { formatUnits } from "@omnity/widget/src/utils/format";

interface TokenVolumeItem {
  historical_volume: string;
  token_id: string;
}

async function fetchTokenVolumes(): Promise<TokenVolumeItem[]> {
  try {
    const doc = gql`
      {
        token_volume {
          historical_volume
          token_id
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.token_volume;
  } catch (error) {
    return [];
  }
}

const stableAtom = atom<TokenVolumeItem[]>([]);

export default function useTotalVolume() {
  const [tokenVolumes, setTokenVolumes] = useAtom(stableAtom);
  const { tokens, priceFetched } = useMetadata();

  useEffect(() => {
    fetchTokenVolumes().then((res) => {
      setTokenVolumes(res);
    });
  }, []);

  const totalVolume = useMemo(() => {
    if (tokenVolumes.length > 0 && priceFetched) {
      let volume = 0;
      tokenVolumes.forEach((t) => {
        const token = tokens.find((tk) => tk.token_id === t.token_id);
        if (token) {
          volume +=
            Number(formatUnits(BigInt(t.historical_volume), token.decimals)) *
            Number(token.price ?? 0);
        }
      });
      return volume;
    }
    return undefined;
  }, [tokenVolumes.length, tokens.length, priceFetched]);

  return totalVolume;
}
