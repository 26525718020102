import {
  Image,
  Stack,
  Link,
  Button,
  VStack,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import HUB_BANNER from "../assets/hub-banner.jpg";
import HUB_IMG1 from "../assets/hub-img1.jpg";
import HUB_IMG2 from "../assets/hub-img2.jpg";
import {
  BagTick,
  Bulb,
  Chart,
  Convert3DCube,
  Cube3D,
  Cube3D2,
  DocumentCode,
  EmptyWallet,
  Share,
  Story,
} from "src/components/svgs";
import ChainGroup from "src/components/ChainGroup";
import Statistics from "src/components/Statistics";

export default function Hub() {
  const px = { base: 4, md: 16 };

  return (
    <Stack w="100%">
      <Image
        src={HUB_BANNER}
        alt="banner"
        objectFit="cover"
        w="100%"
        h="100vh"
        pos="absolute"
      />
      <VStack
        h="100vh"
        mt={120}
        px={px}
        gap={8}
        py={{ base: 8, md: 16 }}
        pos="relative"
      >
        <VStack
          w="100%"
          zIndex={10}
          alignItems="center"
          justifyContent="center"
          gap={8}
          pt={{ base: 12, md: 24 }}
        >
          <VStack
            gap={2}
            fontSize={{ base: 50, md: 80 }}
            lineHeight={1.2}
            fontWeight={700}
          >
            <Text>Omnity Hub: The Fully On-Chain Bitcoin Interoperability</Text>
          </VStack>

          <Text fontSize={20} lineHeight={1.25} maxW={700}>
            Seamlessly connect Bitcoin to L1s and L2s with a trust-minimized,
            fully on-chain architecture
          </Text>

          <Stack
            flexDir={{ base: "column", md: "row" }}
            gap={8}
            justifyContent="center"
          >
            <Button
              background="linear-gradient(90deg, #5A85E9 -16.11%, #B120BD 105.23%)"
              borderRadius="full"
              px={8}
              py={6}
              as={Link}
              href="https://bridge.omnity.network"
              target="_blank"
              fontSize={20}
              color="white"
              _hover={{
                bg: "#B120BD",
                color: "white",
                textDecoration: "none",
                boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
              }}
            >
              Launch App
            </Button>
          </Stack>
        </VStack>
      </VStack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        pt={0}
        gap={12}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Redefining Bitcoin Interoperability
        </Text>
        <Text fontSize={24} fontWeight={400} maxW={1100}>
          Omnity Hub solves the challenges of BTCFi by providing a fully
          on-chain interoperable omnichain protocol operating without the need
          for any off-chain components such as relayers or indexers. Omnity
          stands out for its remarkable speed and cost-efficiency, establishing
          itself as one of the most ideal cross-chain infrastructures for the
          modular blockchain world.
        </Text>
        <Image
          src={HUB_IMG1}
          alt="banner"
          objectFit="contain"
          w="100%"
          borderRadius={16}
        />
      </Stack>

      <Statistics />

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={6}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Integrate with Omnity Hub API
        </Text>
        <Text fontSize={24} fontWeight={400} maxW={1100}>
          Unlock powerful features and seamless connectivity with Omnity Hub
          API.
        </Text>
        <Button
          background="linear-gradient(90deg, #7F5DE6 0%, #E43996 100%)"
          borderRadius="full"
          color="white"
          size="lg"
          px={6}
          as={Link}
          href="https://docs.omnity.network"
          target="_blank"
          _hover={{
            bg: "linear-gradient(90deg, #7F5DE6 0%, #E43996 100%)",
            color: "white",
            textDecoration: "none",
          }}
        >
          Get Started
        </Button>
      </Stack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={20}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Why Choose Omnity Hub?
        </Text>

        <Grid
          w="100%"
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(4, 1fr)`,
          }}
          pos="relative"
          gap={6}
          zIndex={10}
        >
          {whys.map((item) => {
            const Icon = item.icon;
            return (
              <GridItem
                key={item.title}
                w="100%"
                bg={item.bg}
                p={8}
                borderRadius={12}
                role="group"
              >
                <VStack gap={6}>
                  <Icon size={48} />
                  <Text fontWeight={600} fontSize={26} lineHeight={1}>
                    {item.title}
                  </Text>

                  <Text fontSize={20}>{item.subtitle}</Text>
                </VStack>
              </GridItem>
            );
          })}
        </Grid>
      </Stack>

      <ChainGroup />

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={20}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Omnity Hub in Action
        </Text>

        <Grid
          w={{ base: "100%", md: "80%" }}
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          pos="relative"
          gap={6}
          zIndex={10}
        >
          {actions.map((item) => {
            const Icon = item.icon;
            return (
              <GridItem
                key={item.title}
                w="100%"
                bg="linear-gradient(180deg, rgba(114, 62, 201, 0.9) 0%, rgba(48, 32, 100, 0.9) 100%)"
                p={8}
                borderRadius={12}
                role="group"
              >
                <VStack gap={6}>
                  <Icon size={48} />
                  <Text fontWeight={600} fontSize={26} lineHeight={1}>
                    {item.title}
                  </Text>

                  <Text fontSize={20}>{item.subtitle}</Text>
                </VStack>
              </GridItem>
            );
          })}
        </Grid>

        <Image
          src={HUB_IMG2}
          mt={{ base: 4, md: 8 }}
          alt="banner"
          objectFit="contain"
          w={{ base: "100%", md: "90%" }}
          borderRadius={16}
        />
      </Stack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={20}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Applications Powered by Omnity Hub
        </Text>

        <Grid
          w={{ base: "100%", md: "80%" }}
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          pos="relative"
          gap={6}
          zIndex={10}
        >
          {applications.map((item) => {
            const Icon = item.icon;
            return (
              <GridItem
                key={item.title}
                w="100%"
                bg="linear-gradient(180deg, rgba(114, 62, 201, 0.9) 0%, rgba(48, 32, 100, 0.9) 100%)"
                p={8}
                borderRadius={12}
                role="group"
              >
                <VStack gap={6}>
                  <Icon size={48} />
                  <Text fontWeight={600} fontSize={26} lineHeight={1}>
                    {item.title}
                  </Text>

                  <Text fontSize={20}>{item.subtitle}</Text>
                </VStack>
              </GridItem>
            );
          })}
        </Grid>
      </Stack>

      {/* <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={6}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Discover BTCFi Applications
        </Text>
        <Button
          background="linear-gradient(90deg, #7F5DE6 0%, #E43996 100%)"
          borderRadius="full"
          px={6}
        >
          Get Started Now
        </Button>
      </Stack> */}
    </Stack>
  );
}

const whys = [
  {
    icon: Cube3D,
    title: "Fully On-Chain  Architecture ",
    subtitle:
      "Omnity Hub operates entirely on-chain, ensuring maximum transparency and security.",
    bg: "linear-gradient(180deg, rgba(129, 20, 214, 0.8) 0%, rgba(85, 14, 180, 0.8) 106.57%)",
  },
  {
    icon: Share,
    title: "Seamless Chain Integration",
    subtitle:
      "Easily connect Bitcoin to various L1s and L2s blockchains, expanding the possibilities for BTCFi applications.",
    bg: "linear-gradient(180deg, rgba(143, 61, 201, 0.8) 0%, rgba(101, 32, 139, 0.8) 100%)",
  },
  {
    icon: DocumentCode,
    title: "Trust-Minimized Protocol",
    subtitle:
      "Minimize reliance on intermediaries with innovative, trustless bridging mechanism.",
    bg: "linear-gradient(180deg, rgba(143, 61, 201, 0.8) 0%, rgba(101, 32, 139, 0.8) 100%)",
  },
  {
    icon: Story,
    title: "Enhanced Liquidity",
    subtitle:
      "Unlock new liquidity opportunities by bridging Bitcoin to multiple chains simultaneously.",
    bg: "linear-gradient(180deg, rgba(129, 20, 214, 0.8) 0%, rgba(85, 14, 180, 0.8) 106.57%)",
  },
];

const actions = [
  {
    icon: BagTick,
    title: "Chain Key Locking",
    subtitle:
      "Cross-chain Bitcoin assets are securely holded by ICP Chain Key subset.",
  },
  {
    icon: EmptyWallet,
    title: "Fungible Wrapper Tokens",
    subtitle:
      "Bitcoin assets wrapper tokens roam around L1s and L2s with fungiblility kept.",
  },
  {
    icon: Cube3D2,
    title: "Fully On-Chain",
    subtitle:
      "Transactions are processed entirely on-chain, ensuring transparency and security.",
  },
];

const applications = [
  {
    icon: Convert3DCube,
    title: "Cross-Chain Lending",
    subtitle:
      "Use Bitcoin as collateral on other chains, unlocking new borrowing opportunities.",
    bg: "linear-gradient(180deg, rgba(114, 62, 201, 0.9) 0%, rgba(48, 32, 100, 0.9) 100%)",
  },
  {
    icon: Chart,
    title: "Decentralized Trading",
    subtitle:
      "Enable Bitcoin trading across chains via AMMs, increasing liquidity and trading options.",
    bg: "linear-gradient(180deg, rgba(143, 61, 201, 0.8) 0%, rgba(101, 32, 139, 0.8) 100%)",
  },
  {
    icon: Bulb,
    title: "Yield Opportunities",
    subtitle:
      "Access staking and farming opportunities on connected chains using your Bitcoin assets.",
    bg: "linear-gradient(180deg, rgba(114, 62, 201, 0.9) 0%, rgba(48, 32, 100, 0.9) 100%)",
  },
];
