import {
  VStack,
  HStack,
  Text,
  Image,
  Button,
  Grid,
  GridItem,
  Link,
  Stack,
} from "@chakra-ui/react";
import LIGHT_BG from "../assets/light-bg.png";
import LOGO_1 from "../assets/logo-1.svg";
import LOGO_2 from "../assets/logo-2.svg";
import LOGO_3 from "../assets/logo-3.svg";
import LOGO_4 from "../assets/logo-4.svg";
import LOGOMARK_1 from "../assets/logomark-1.svg";
import LOGOMARK_2 from "../assets/logomark-2.svg";
import LOGOMARK_3 from "../assets/logomark-3.svg";
import { LINK_HOVER_COLOR } from "../utils/constants";

function CustomButton({ label, href }: { label: string; href: string }) {
  return (
    <Button
      px={8}
      rounded="full"
      bg="rgba(29, 34, 85, 1)"
      color="white"
      _hover={{
        bg: "rgba(64, 78, 160, 1)",
        color: LINK_HOVER_COLOR,
        textDecoration: "none",
      }}
      _active={{
        bg: "rgba(29, 34, 85, 1)",
        color: LINK_HOVER_COLOR,
        textDecoration: "none",
      }}
      as={Link}
      href={href}
    >
      {label}
    </Button>
  );
}

export default function Brand() {
  return (
    <VStack>
      <HStack pt={{ base: 8, md: 8 }}>
        <Text fontSize={{ base: 32, md: 60 }} fontWeight={700}>
          Omnity brand assets
        </Text>
      </HStack>
      <Image src={LIGHT_BG} alt="bg" opacity={0.7} />

      <VStack px={{ base: 4, md: 16 }} py={8} gap={{ base: 8, md: 16 }}>
        <Stack
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text fontSize={{ base: 26, md: 48 }} fontWeight={700}>
            Main logotype
          </Text>

          <HStack gap={6}>
            <CustomButton label="PNG" href="/Omnity-logo-png.zip" />
            <CustomButton label="SVG" href="/Omnity-logo-svg.zip" />
          </HStack>
        </Stack>

        <Grid
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          gap={8}
        >
          <GridItem>
            <Image src={LOGO_1} alt="logo-1" />
          </GridItem>
          <GridItem>
            <Image src={LOGO_2} alt="logo-2" />
          </GridItem>
          <GridItem>
            <Image src={LOGO_3} alt="logo-3" />
          </GridItem>
          <GridItem>
            <Image src={LOGO_4} alt="logo-4" />
          </GridItem>
        </Grid>
      </VStack>

      <VStack px={{ base: 4, md: 16 }} py={8} gap={{ base: 8, md: 16 }}>
        <Stack
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text fontSize={{ base: 26, md: 48 }} fontWeight={700}>
            Logomark
          </Text>

          <HStack gap={6}>
            <CustomButton label="PNG" href="/Omnity-logomark-png.zip" />
            <CustomButton label="SVG" href="/Omnity-logomark-svg.zip" />
          </HStack>
        </Stack>

        <Grid
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          gap={8}
        >
          <GridItem>
            <Image src={LOGOMARK_1} alt="logomark-1" />
          </GridItem>
          <GridItem>
            <Image src={LOGOMARK_2} alt="logomark-2" />
          </GridItem>
          <GridItem>
            <Image src={LOGOMARK_3} alt="logomark-3" />
          </GridItem>
        </Grid>
      </VStack>

      <Image src={LIGHT_BG} alt="bg" opacity={0.7} />
    </VStack>
  );
}
