import {
  HStack,
  Image,
  Link,
  VStack,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Text,
  Button,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import LOGO from "../assets/logo.svg";
import OMNITY from "../assets/omnity.svg";
import { useEffect, useRef, useState } from "react";
import { LINK_HOVER_COLOR } from "../utils/constants";
import { ArrowUpRight, Menu, ChevronDown } from "lucide-react";

const HamburgerIcon = chakra(Menu);

const MENUS = [
  {
    title: "Ecosystem",
    href: "#",
    subs: [
      {
        title: "Omnity Hub",
        href: "https://bridge.omnity.network",
      },
      {
        title: "Omnity Hub Explorer",
        href: "https://explorer.omnity.network",
      },
    ],
  },
  {
    title: "Learn",
    href: "#",
    subs: [
      {
        title: "Omnity Hub Whitepaper",
        href: "https://docs.google.com/document/d/10RSrGfj2Z-LmVhuAXX0Jon-9P9CO6k5ZNfS2hrQB72I/edit?tab=t.0#heading=h.5qlvro4pcib6",
      },
      {
        title: "REE Whitepaper",
        href: "https://docs.google.com/document/d/1d1_51f8YYRhxft_RpGssCKqS95ZE5Ylv1LDleIqVZJE/edit?tab=t.0#heading=h.9hfttub7lmzc",
      },
      {
        title: "Omnity Hub API",
        href: "https://docs.omnity.network",
      },
    ],
  },
  {
    title: "Blog",
    href: "https://medium.com/omnity",
  },
  {
    title: "Docs",
    href: "https://docs.omnity.network",
  },
];

const ChevronDownIcon = chakra(ChevronDown);

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const [overScrolled, setOverScrolled] = useState(false);

  const handleScroll = () => {
    const positionY = window.scrollY;
    if (positionY > 100) {
      setOverScrolled(true);
    } else {
      setOverScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HStack
      zIndex={100}
      px={{ base: 4, md: 16 }}
      py={{ base: 2, md: 8 }}
      justifyContent="space-between"
      position="fixed"
      w="100%"
      bg={overScrolled ? "#000433" : "transparent"}
    >
      <Link href="/">
        <HStack alignItems="center" gap={2}>
          <Image src={LOGO} alt="logo" />
          <Image src={OMNITY} pos="relative" top={1} alt="omnity" />
        </HStack>
      </Link>

      <HStack gap={8} display={{ base: "none", md: "flex" }}>
        {MENUS.map((m, idx) => {
          if (m.subs) {
            return (
              <Popover>
                <PopoverTrigger>
                  <HStack
                    cursor="pointer"
                    alignItems="center"
                    gap={1}
                    fontSize={16}
                    _hover={{ color: "gray.100" }}
                  >
                    <Text fontSize={20} fontWeight={600}>
                      {m.title}
                    </Text>
                    <ChevronDownIcon pos="relative" top={1} size={20} />
                  </HStack>
                </PopoverTrigger>
                <PopoverContent bg="rgba(25, 12, 53, 1)" px={4} py={2}>
                  <PopoverBody alignItems="flex-start">
                    {m.subs.map((sub, index) => (
                      <Link
                        key={index}
                        href={sub.href}
                        color="white"
                        py={4}
                        target="_blank"
                        _hover={{
                          textDecoration: "none",
                          color: "#AB28C0",
                        }}
                      >
                        <Text fontSize={20} textAlign="left">
                          {sub.title}
                        </Text>
                      </Link>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            );
          }
          return (
            <Link
              key={idx}
              href={m.href}
              color="white"
              fontSize={18}
              _hover={{ color: LINK_HOVER_COLOR }}
              target={m.href.startsWith("http") ? "_blank" : ""}
            >
              <HStack
                alignItems="center"
                gap={1}
                fontSize={16}
                _hover={{ color: "gray.100" }}
              >
                <Text fontSize={20}>{m.title}</Text>
              </HStack>
            </Link>
          );
        })}
        <Button
          background="linear-gradient(90deg, #5A85E9 0%, #AB28C0 106.49%)"
          borderRadius="full"
          px={8}
          py={0}
          as={Link}
          href="/hub"
          color="white"
          _hover={{
            bg: "linear-gradient(90deg, #5A85E9 0%, #AB28C0 106.49%)",
            color: "white",
            textDecoration: "none",
            boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
          }}
        >
          Hub
        </Button>
        <Button
          background="linear-gradient(90deg, #D53790 0%, #F29032 106.49%)"
          borderRadius="full"
          px={8}
          py={0}
          as={Link}
          href="/ree"
          color="white"
          _hover={{
            bg: "linear-gradient(90deg, #D53790 0%, #F29032 106.49%)",
            color: "white",
            textDecoration: "none",
            boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
          }}
        >
          REE
        </Button>
      </HStack>

      <Text display={{ base: "inline", md: "none" }} onClick={onOpen}>
        <HamburgerIcon color="white" size={24} />
      </Text>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent bg="#000433" color="white">
          <DrawerCloseButton color="white" />
          <DrawerBody>
            <VStack gap={4} pt={24} alignItems="flex-end">
              {MENUS.map((m, idx) => {
                return (
                  <Link
                    key={idx}
                    href={m.href}
                    fontSize={24}
                    onClick={onClose}
                    color="white"
                    _hover={{
                      color: LINK_HOVER_COLOR,
                    }}
                    target={m.href.startsWith("http") ? "_blank" : ""}
                  >
                    <HStack key={idx} gap={1} _hover={{ color: "gray.100" }}>
                      <Text className="number" fontSize={30}>
                        {m.title}
                      </Text>
                      {m.href.startsWith("http") && <ArrowUpRight size={16} />}
                    </HStack>
                  </Link>
                );
              })}
              <Button
                background="linear-gradient(90deg, #5A85E9 0%, #AB28C0 106.49%)"
                borderRadius="full"
                fontSize={26}
                px={8}
                py={4}
                as={Link}
                href="/hub"
                color="white"
                _hover={{
                  bg: "linear-gradient(90deg, #5A85E9 0%, #AB28C0 106.49%)",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Hub
              </Button>
              <Button
                background="linear-gradient(90deg, #D53790 0%, #F29032 106.49%)"
                borderRadius="full"
                fontSize={26}
                px={8}
                py={4}
                as={Link}
                href="/ree"
                color="white"
                _hover={{
                  bg: "linear-gradient(90deg, #D53790 0%, #F29032 106.49%)",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                REE
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
}
