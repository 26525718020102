import "./global.css";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import { Stack } from "@chakra-ui/react";
import { Analytics } from "@vercel/analytics/react";

export default function Layout() {
  return (
    <Stack bg="#000433" className="outfit" color="white">
      <Header />
      <Analytics />
      <Outlet />
      <Footer />
    </Stack>
  );
}
