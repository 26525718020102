import { Actor, HttpAgent } from "@dfinity/agent";
import { IDL } from "@dfinity/candid";
import {
  idlFactory as NodeIndexFactory,
  _SERVICE as NodeIndexService,
} from "./NodeIndex.did";

export const createActor = async <T>(
  canisterId: string,
  interfaceFactory: IDL.InterfaceFactory,
) => {
  const agent = await HttpAgent.create({
    host: "https://icp0.io/",
  });
  return Actor.createActor<T>(interfaceFactory, {
    canisterId,
    agent,
  });
};

export const getICPSwapIndexService = async () => {
  return createActor<NodeIndexService>(
    "ggzvv-5qaaa-aaaag-qck7a-cai",
    NodeIndexFactory,
  );
};
