export const Share = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M33.92 12.34c4 2.78 6.76 7.2 7.32 12.3M6.98 24.74c.52-5.08 3.24-9.5 7.2-12.3M16.38 41.88a17.05 17.05 0 0 0 7.74 1.84c2.68 0 5.2-.6 7.46-1.7M24.12 15.4a5.56 5.56 0 1 0 0-11.12 5.56 5.56 0 0 0 0 11.12M9.66 39.84a5.56 5.56 0 1 0 0-11.12 5.56 5.56 0 0 0 0 11.12M38.34 39.84a5.56 5.56 0 1 0 0-11.12 5.56 5.56 0 0 0 0 11.12"
    ></path>
  </svg>
);

export const Cube3D = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m25.84 4.52 13.02 7.02c1.52.82 1.52 3.16 0 3.98l-13.02 7.02c-1.16.62-2.52.62-3.68 0L9.14 15.52c-1.52-.82-1.52-3.16 0-3.98l13.02-7.02c1.16-.62 2.52-.62 3.68 0M7.22 20.26l12.1 6.06c1.5.76 2.46 2.3 2.46 3.98v11.44c0 1.66-1.74 2.72-3.22 1.98l-12.1-6.06C4.96 36.9 4 35.36 4 33.68V22.24c0-1.66 1.74-2.72 3.22-1.98M40.78 20.26l-12.1 6.06c-1.5.76-2.46 2.3-2.46 3.98v11.44c0 1.66 1.74 2.72 3.22 1.98l12.1-6.06c1.5-.76 2.46-2.3 2.46-3.98V22.24c0-1.66-1.74-2.72-3.22-1.98"
    ></path>
  </svg>
);

export const DocumentCode = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M42 14v20c0 6-3 10-10 10H16C9 44 6 40 6 34V14C6 8 9 4 16 4h16c7 0 10 4 10 10"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M29 9v4c0 2.2 1.8 4 4 4h4M20 26l-4 4 4 4M28 26l4 4-4 4"
    ></path>
  </svg>
);

export const Story = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M32.84 15.9c4.88 4.88 4.88 12.8 0 17.68s-12.8 4.88-17.68 0-4.88-12.8 0-17.68 12.8-4.88 17.68 0M16.5 43.28c-4-1.6-7.5-4.5-9.82-8.52a19.78 19.78 0 0 1-2.5-12.5M11.7 8.96C15.1 6.3 19.36 4.72 24 4.72c4.54 0 8.72 1.54 12.08 4.1M31.5 43.28c4-1.6 7.5-4.5 9.82-8.52 2.28-3.94 3.04-8.32 2.5-12.5"
    ></path>
  </svg>
);

export const BagTick = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M24 38a8 8 0 1 0 0-16 8 8 0 0 0 0 16"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M22 27.25h3.13c.69 0 1.25.63 1.25 1.25 0 .69-.56 1.25-1.25 1.25H22zM22 29.75h3.57c.79 0 1.43.56 1.43 1.25s-.64 1.25-1.43 1.25H22zM23.76 32.25v1.25M23.76 26v1.25M23.19 27.25H21M23.19 32.25H21"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M18 44h12c8.04 0 9.48-3.22 9.9-7.14l1.5-12c.54-4.88-.86-8.86-9.4-8.86H16c-8.54 0-9.94 3.98-9.4 8.86l1.5 12C8.52 40.78 9.96 44 18 44M15 15.34V13.4c0-4.5 3.62-8.92 8.12-9.34 5.36-.52 9.88 3.7 9.88 8.96v2.76"
    ></path>
  </svg>
);

export const EmptyWallet = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M36.08 27.1c-.84.82-1.32 2-1.2 3.26.18 2.16 2.16 3.74 4.32 3.74H43v2.38c0 4.14-3.38 7.52-7.52 7.52H15.26c.62-.52 1.16-1.16 1.58-1.88C17.58 40.92 18 39.5 18 38c0-4.42-3.58-8-8-8-1.88 0-3.62.66-5 1.76v-8.74c0-4.14 3.38-7.52 7.52-7.52h22.96c4.14 0 7.52 3.38 7.52 7.52v2.88h-4.04c-1.12 0-2.14.44-2.88 1.2"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5 24.82v-9.14c0-2.38 1.46-4.5 3.68-5.34l15.88-6c2.48-.94 5.14.9 5.14 3.56v7.6M45.118 27.94v4.12c0 1.1-.88 2-2 2.04h-3.92c-2.16 0-4.14-1.58-4.32-3.74-.12-1.26.36-2.44 1.2-3.26.74-.76 1.76-1.2 2.88-1.2h4.16c1.12.04 2 .94 2 2.04M14 24h14"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M18 38c0 1.5-.42 2.92-1.16 4.12-.42.72-.96 1.36-1.58 1.88A7.8 7.8 0 0 1 10 46a7.94 7.94 0 0 1-6.84-3.88A7.84 7.84 0 0 1 2 38c0-2.52 1.16-4.78 3-6.24A8 8 0 0 1 10 30c4.42 0 8 3.58 8 8"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m6.883 37.999 1.98 1.98 4.26-3.94"
    ></path>
  </svg>
);

export const Cube3D2 = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M4 18v-4C4 8 8 4 14 4h20c6 0 10 4 10 10v4M4 30v4c0 6 4 10 10 10h20c6 0 10-4 10-10v-4"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.4 18.52 24 24.66l10.52-6.1M24 35.54v-10.9"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m21.52 12.58-6.4 3.56c-1.44.8-2.64 2.82-2.64 4.48v6.78c0 1.66 1.18 3.68 2.64 4.48l6.4 3.56c1.36.76 3.6.76 4.98 0l6.4-3.56c1.44-.8 2.64-2.82 2.64-4.48v-6.78c0-1.66-1.18-3.68-2.64-4.48l-6.4-3.56c-1.38-.78-3.62-.78-4.98 0"
    ></path>
  </svg>
);

export const Convert3DCube = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M44 30c0 7.74-6.26 14-14 14l2.1-3.5M4 18c0-7.74 6.26-14 14-14l-2.1 3.5"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M27 12.052h17"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M30.774 6h9.452C43.252 6 44 6.748 44 9.74v6.987c0 2.992-.748 3.74-3.774 3.74h-9.452c-3.026 0-3.774-.748-3.774-3.74V9.74C27 6.748 27.748 6 30.774 6M4.7 30.9l7.94 4.6 7.9-4.58M12.64 43.64v-8.16"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m10.78 26.42-4.8 2.66C4.9 29.68 4 31.2 4 32.44v5.08c0 1.24.88 2.76 1.98 3.36l4.8 2.66c1.02.58 2.7.58 3.74 0l4.8-2.66c1.08-.6 1.98-2.12 1.98-3.36v-5.08c0-1.24-.88-2.76-1.98-3.36l-4.8-2.66c-1.04-.56-2.72-.56-3.74 0"
    ></path>
  </svg>
);

export const Chart = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6 44h36M11.2 16.76H8c-1.1 0-2 .9-2 2V36c0 1.1.9 2 2 2h3.2c1.1 0 2-.9 2-2V18.76c0-1.1-.9-2-2-2M25.6 10.38h-3.2c-1.1 0-2 .9-2 2V36c0 1.1.9 2 2 2h3.2c1.1 0 2-.9 2-2V12.38c0-1.1-.9-2-2-2M40 4h-3.2c-1.1 0-2 .9-2 2v30c0 1.1.9 2 2 2H40c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2"
    ></path>
  </svg>
);

export const Bulb = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.6 36.08v-2.32c-4.6-2.78-8.38-8.2-8.38-13.96 0-9.9 9.1-17.66 19.38-15.42 4.52 1 8.48 4 10.54 8.14 4.18 8.4-.22 17.32-6.68 21.22v2.32c0 .58.22 1.92-1.92 1.92H18.52c-2.2.02-1.92-.84-1.92-1.9M17 44c4.58-1.3 9.42-1.3 14 0"
    ></path>
  </svg>
);

export const SimCard = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m39.08 13.08-6.14-6.14a9.99 9.99 0 0 0-7.08-2.92H16c-6 0-10 4-10 10v20c0 6 4 10 10 10h16c6 0 10-4 10-10V20.16c0-2.68-1.06-5.22-2.92-7.08"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m19 28-4 4 4 4M29 28l4 4-4 4"
    ></path>
  </svg>
);

export const Exchange = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <mask
      id="mask0_2249_2340"
      width={size}
      height={size}
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "luminance" }}
    >
      <path fill="#fff" d="M48 0H0v48h48z"></path>
    </mask>
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      mask="url(#mask0_2249_2340)"
    >
      <path d="M10 26a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H14a4 4 0 0 1-4-4z"></path>
      <path d="M22 32a2 2 0 1 0 4 0 2 2 0 0 0-4 0M16 22V12a8 8 0 0 1 16 0"></path>
    </g>
  </svg>
);

export const BitcoinRefresh = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m8.94 22.84-3.48-3.48L2 22.84M39.06 25.16l3.48 3.48 3.48-3.48"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M42.52 28.64V24c0-10.24-8.3-18.52-18.52-18.52-5.84 0-11.06 2.72-14.46 6.94M5.48 19.36V24c0 10.24 8.3 18.52 18.52 18.52 5.84 0 11.06-2.72 14.46-6.94"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M18 17h8.76c1.94 0 3.5 1.76 3.5 3.5 0 1.94-1.56 3.5-3.5 3.5H18zM18 24h10c2.2 0 4 1.56 4 3.5S30.2 31 28 31H18zM23.6 31v3.5M23.6 13.5V17"
    ></path>
  </svg>
);

export const BitcoinConvert = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M46 31.94c0 7.74-6.26 14-14 14l2.1-3.5M2 15.94c0-7.74 6.26-14 14-14l-2.1 3.5"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M13.24 26.14h5.62c1.24 0 2.26 1.12 2.26 2.26 0 1.24-1 2.26-2.26 2.26h-5.62zM13.24 30.66h6.44c1.42 0 2.58 1 2.58 2.26 0 1.24-1.16 2.26-2.58 2.26h-6.44zM16.84 35.16v2.24M16.84 23.9v2.24"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M29.7 30.66C29.7 37.48 24.18 43 17.36 43S5.02 37.48 5.02 30.66s5.52-12.34 12.34-12.34c.32 0 .62.02.96.04 6.06.46 10.9 5.3 11.36 11.36 0 .3.02.6.02.94"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M43 17.34c0 6.82-5.52 12.34-12.34 12.34h-.98c-.46-6.06-5.3-10.9-11.36-11.36v-.98C18.32 10.52 23.84 5 30.66 5S43 10.52 43 17.34"
    ></path>
  </svg>
);

export const Check = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0_2249_2281)">
      <mask
        id="mask0_2249_2281"
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path fill="#fff" d="M24 0H0v24h24z"></path>
      </mask>
      <g mask="url(#mask0_2249_2281)">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="m5 12 5 5L20 7"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2249_2281">
        <path fill="#fff" d="M0 0h24v24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const WalletMoney = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21.503 33.72v4.06c0 3.44-3.2 6.22-7.14 6.22s-7.16-2.78-7.16-6.22v-4.06c0 3.44 3.2 5.88 7.16 5.88 3.94 0 7.14-2.46 7.14-5.88"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21.5 28.22c0 1-.28 1.92-.76 2.72-1.18 1.94-3.6 3.16-6.4 3.16s-5.22-1.24-6.4-3.16c-.48-.8-.76-1.72-.76-2.72 0-1.72.8-3.26 2.08-4.38 1.3-1.14 3.08-1.82 5.06-1.82s3.76.7 5.06 1.82c1.32 1.1 2.12 2.66 2.12 4.38"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21.503 28.22v5.5c0 3.44-3.2 5.88-7.14 5.88s-7.16-2.46-7.16-5.88v-5.5c0-3.44 3.2-6.22 7.16-6.22 1.98 0 3.76.7 5.06 1.82 1.28 1.12 2.08 2.68 2.08 4.4M44 21.94v4.12c0 1.1-.88 2-2 2.04h-3.92c-2.16 0-4.14-1.58-4.32-3.74-.12-1.26.36-2.44 1.2-3.26.74-.76 1.76-1.2 2.88-1.2H42c1.12.04 2 .94 2 2.04"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4 21v-4c0-5.44 3.28-9.24 8.38-9.88Q13.16 7 14 7h18c.52 0 1.02.02 1.5.1 5.16.6 8.5 4.42 8.5 9.9v2.9h-4.16c-1.12 0-2.14.44-2.88 1.2-.84.82-1.32 2-1.2 3.26.18 2.16 2.16 3.74 4.32 3.74H42V31c0 6-4 10-10 10h-5"
    ></path>
  </svg>
);

export const Convert = ({ size = 24 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M44 17c0 7.18-5.82 13-13 13-.34 0-.7-.02-1.04-.04-.5-6.34-5.58-11.42-11.92-11.92-.02-.34-.04-.7-.04-1.04 0-7.18 5.82-13 13-13s13 5.82 13 13"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M30 31c0 7.18-5.82 13-13 13S4 38.18 4 31s5.82-13 13-13c.34 0 .7.02 1.04.04 6.34.5 11.42 5.58 11.92 11.92.02.34.04.7.04 1.04M11.18 4H6c-1.1 0-2 .9-2 2v5.18c0 1.78 2.16 2.68 3.42 1.42l5.18-5.18C13.84 6.16 12.96 4 11.18 4M36.82 44H42c1.1 0 2-.9 2-2v-5.18c0-1.78-2.16-2.68-3.42-1.42l-5.18 5.18c-1.24 1.26-.36 3.42 1.42 3.42"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.5 33.528c0 1.4 1.083 2.528 2.412 2.528h2.716c1.155 0 2.094-.983 2.094-2.21 0-1.315-.578-1.792-1.43-2.095l-4.348-1.517c-.852-.303-1.43-.765-1.43-2.094 0-1.213.94-2.21 2.095-2.21h2.715c1.33 0 2.413 1.127 2.413 2.528M17.111 24.5v13"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M28.6 12h5.008c1.104 0 2 1.008 2 2a2 2 0 0 1-2 2H28.6zM28.6 16h5.712c1.264 0 2.288.896 2.288 2s-1.024 2-2.288 2H28.6zM31.416 20v2M31.416 10v2M30.504 12H27M30.504 20H27"
    ></path>
  </svg>
);
