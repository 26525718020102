import { Button } from "@chakra-ui/react";

export default function Subscribe() {
  return (
    <Button
      bg="linear-gradient(90deg, #7F5DE6 0%, #E43996 100%)"
      borderRadius="full"
      size="lg"
      px={6}
      py={4}
      _hover={{
        bg: "linear-gradient(90deg, #7F5DE6 0%, #E43996 100%)",
        color: "white",
        textDecoration: "none",
        boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
      }}
    >
      <a href="#mailmunch-pop-1150870" style={{ color: "#FFF" }}>
        Subscribe
      </a>
    </Button>
  );
}
