import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/home.tsx";
import Brand from "./routes/brand.tsx";
import Layout from "./layout.tsx";
import { MetadataProvider } from "./contexts/metadata.tsx";
import Hub from "./routes/hub.tsx";
import Ree from "./routes/ree.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "hub",
        element: <Hub />,
      },
      {
        path: "ree",
        element: <Ree />,
      },
      {
        path: "brand",
        element: <Brand />,
      },
    ],
  },
]);

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <MetadataProvider>
        <RouterProvider router={router} />
      </MetadataProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
