import {
  Image,
  Stack,
  Button,
  VStack,
  Text,
  Grid,
  GridItem,
  HStack,
  Link,
  Avatar,
} from "@chakra-ui/react";
import REE_BANNER from "../assets/ree-banner.jpg";
import IMG1 from "../assets/ree-img1.png";
import IMG2 from "../assets/ree-img2.png";
import IMG3 from "../assets/ree-img3.png";
import RICH from "../assets/rich.svg";
import {
  BitcoinConvert,
  BitcoinRefresh,
  Chart,
  Check,
  Convert,
  Exchange,
  SimCard,
  WalletMoney,
} from "src/components/svgs";

export default function Ree() {
  const px = { base: 4, md: 16 };

  return (
    <Stack w="100%">
      <Image
        src={REE_BANNER}
        alt="banner"
        objectFit="cover"
        w="100%"
        h="100vh"
        pos="absolute"
      />
      <VStack
        h="100vh"
        mt={120}
        px={px}
        gap={8}
        py={{ base: 8, md: 16 }}
        pos="relative"
      >
        <VStack
          w="100%"
          zIndex={10}
          alignItems="center"
          justifyContent="center"
          gap={8}
          pt={{ base: 12, md: 24 }}
        >
          <VStack
            gap={2}
            fontSize={{ base: 50, md: 80 }}
            lineHeight={1.2}
            fontWeight={700}
          >
            <Text maxW={800}>REE: Runes Exchange Environment</Text>
          </VStack>

          <Text fontSize={20} lineHeight={1.25} maxW={700}>
            Unlock Bitcoin-native programmability with a decentralized
            Turing-complete execution layer.
          </Text>

          <Stack
            flexDir={{ base: "column", md: "row" }}
            gap={8}
            justifyContent="center"
          >
            <Button
              background="linear-gradient(90deg, #D53790 0%, #F29032 100%)"
              borderRadius="full"
              px={8}
              py={6}
              as={Link}
              color="white"
              href="https://docs.google.com/document/d/1d1_51f8YYRhxft_RpGssCKqS95ZE5Ylv1LDleIqVZJE/edit?tab=t.0#heading=h.9hfttub7lmzc"
              target="_blank"
              fontSize={20}
              _hover={{
                bg: "linear-gradient(90deg, #D53790 0%, #F29032 100%)",
                color: "white",
                textDecoration: "none",
                boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
              }}
            >
              REE Whitepaper
            </Button>
          </Stack>
        </VStack>
      </VStack>

      <Stack
        flexDirection={{ base: "column", md: "row" }}
        px={px}
        py={24}
        pt={0}
        gap={16}
        justifyContent="center"
        alignItems="center"
      >
        <VStack alignItems="flex-start" gap={6}>
          <Text textAlign="left" fontSize={48} fontWeight={600}>
            Dawn of Bitcoin Native DeFi
          </Text>
          <Text textAlign="left" fontSize={18} fontWeight={400} maxW={1100}>
            REE is a revolutionary Bitcoin execution layer built on{" "}
            <Link
              href="https://internetcomputer.org/chainfusion"
              target="_blank"
            >
              <Text
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                display="inline-block"
                fontWeight={600}
              >
                ICP Chain Fusion
              </Text>
            </Link>
            , enabling composable Turing-complete smart contracts without the
            need for bridges or non-Bitcoin wallets. REE opens up a new world of
            decentralized applications and financial instruments on Bitcoin.
          </Text>
        </VStack>
        <Image
          src={IMG1}
          alt="banner"
          objectFit="contain"
          w={{ base: "100%", md: "50%" }}
        />
      </Stack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={24}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          What Makes REE Unique?
        </Text>

        <Grid
          w="100%"
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(4, 1fr)`,
          }}
          pos="relative"
          gap={6}
          zIndex={10}
        >
          {uniques.map((item) => {
            const Icon = item.icon;
            return (
              <GridItem
                key={item.title}
                w="100%"
                bg={item.bg}
                p={8}
                borderRadius={12}
                role="group"
              >
                <VStack gap={6}>
                  <Icon size={48} />
                  <Text fontWeight={600} fontSize={26} lineHeight={1}>
                    {item.title}
                  </Text>

                  <Text fontSize={20}>{item.subtitle}</Text>
                </VStack>
              </GridItem>
            );
          })}
        </Grid>
      </Stack>

      <Stack
        flexDirection={{ base: "column", md: "row" }}
        px={px}
        py={24}
        gap={16}
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={IMG2}
          alt="banner"
          objectFit="contain"
          w={{ base: "100%", md: "45%" }}
        />
        <VStack alignItems="flex-start" gap={6}>
          <Text textAlign="left" fontSize={40} fontWeight={600}>
            RichSwap: The world 1st Runes AMM DEX, built on REE.
          </Text>
          <Text textAlign="left" fontSize={18} fontWeight={400} maxW={1100}>
            Richswap is the first DApp built on REE, demonstrating the
            platform's capabilities for complex DeFi applications. RichSwap to
            all BTCFi builders:
            <Link
              href="https://www.runescan.net/runes/431"
              target="_blank"
              ml={2}
              mr={1}
              pos="relative"
              top={2}
            >
              <HStack display="inline-flex" gap={1}>
                <Avatar src={RICH} size="xs" />

                <Text
                  bgGradient="linear(to-l, #7928CA, #FF0080)"
                  bgClip="text"
                  display="inline-block"
                  fontWeight={600}
                >
                  HOPE•YOU•GET•RICH
                </Text>
              </HStack>
            </Link>
            !
          </Text>

          <VStack alignItems="flex-start">
            {[
              "Native Bitcoin trading pairs",
              "Low fees and high liquidity",
              "Seamless integration with Bitcoin wallets",
              "Advanced trading features powered by REE",
            ].map((t) => {
              return (
                <HStack key={t}>
                  <Check size={24} />
                  <Text>{t}</Text>
                </HStack>
              );
            })}
          </VStack>

          <Button
            borderRadius="full"
            bg="linear-gradient(90deg, #D53790 0%, #F29032 100%)"
            size="lg"
            color="white"
            _hover={{
              bg: "linear-gradient(90deg, #D53790 0%, #F29032 100%)",
              boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
            }}
          >
            Launch (coming soon)
          </Button>
        </VStack>
      </Stack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={20}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          REE's Technical Innovations
        </Text>

        <Grid
          w={{ base: "100%", md: "90%" }}
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          pos="relative"
          gap={8}
          zIndex={10}
        >
          {innovations.map((item) => {
            return (
              <GridItem
                key={item.title}
                w="100%"
                bg={item.bg}
                p={8}
                borderRadius={12}
                role="group"
              >
                <VStack gap={6}>
                  <Text fontWeight={600} fontSize={26} lineHeight={1}>
                    {item.title}
                  </Text>

                  <Text fontSize={20}>{item.subtitle}</Text>
                </VStack>
              </GridItem>
            );
          })}
        </Grid>

        <Image
          src={IMG3}
          alt="banner"
          objectFit="contain"
          w={{ base: "100%", md: "80%" }}
        />
      </Stack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={20}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          BTCFi DApps Powered by REE
        </Text>

        <Grid
          w={{ base: "100%", md: "80%" }}
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          pos="relative"
          gap={12}
          zIndex={10}
        >
          {dapps.map((item) => {
            const Icon = item.icon;
            return (
              <GridItem
                key={item.title}
                w="100%"
                bg={item.bg}
                p={8}
                borderRadius={12}
                role="group"
              >
                <VStack gap={6}>
                  <Icon size={48} />
                  <Text fontWeight={600} fontSize={26} lineHeight={1}>
                    {item.title}
                  </Text>

                  <Text fontSize={20}>{item.subtitle}</Text>
                </VStack>
              </GridItem>
            );
          })}
        </Grid>
        {/*
        <Button
          background="linear-gradient(90deg, #D53790 0%, #F29032 100%)"
          borderRadius="full"
          px={8}
          py={6}
          fontSize={20}
          _hover={{
            bg: "#F29032",
          }}
        >
          Learn About Richswap
        </Button> */}
      </Stack>
    </Stack>
  );
}

const uniques = [
  {
    icon: SimCard,
    title: "Turing-Complete Programmability",
    subtitle:
      "Build complex smart contracts and DApps directly on Bitcoin, unlocking its full potential.",
    bg: "linear-gradient(159.15deg, rgba(143, 61, 201, 0.8) 6.76%, rgba(48, 32, 100, 0.8) 100%)",
  },
  {
    icon: BitcoinRefresh,
    title: "Bitcoin Native UX",
    subtitle:
      "Interact with REE smart contracts with Bitcoin wallets without bridging or assets wrapping.",
    bg: "linear-gradient(140.11deg, rgba(143, 61, 201, 0.8) 0%, rgba(226, 101, 80, 0.8) 100%)",
  },
  {
    icon: BitcoinConvert,
    title: "Composable BTCFi",
    subtitle:
      "Composability allows BTCFi protocols on REE to share liquidity and components.",
    bg: "linear-gradient(159.15deg, rgba(143, 61, 201, 0.8) 6.76%, rgba(48, 32, 100, 0.8) 100%)",
  },
  {
    icon: Exchange,
    title: "Open and Permissionless",
    subtitle:
      "Anyone can build and deploy smart contracts and DApps on REE, fostering innovation and decentralization.",
    bg: "linear-gradient(140.11deg, rgba(143, 61, 201, 0.8) 0%, rgba(226, 101, 80, 0.8) 100%)",
  },
];

const innovations = [
  {
    title: "Exchange-Pool Model",
    subtitle:
      "REE's Exchange-Pool Model harmonizes account-based public blockchains with UTXO-based Bitcoin state, enabling ICP smart contracts handle Bitcoin assets.",
    bg: "linear-gradient(159.15deg, #8F3DC9 6.76%, #302064 100%)",
  },
  {
    title: "Composable BTCFi",
    subtitle:
      "REE smart contract composability ensures seamless integration across protocols, enabling innovative financial products by combining liquidity and logic in a trust-minimized framework.",
    bg: "linear-gradient(140.11deg, rgba(143, 61, 201, 0.8) 0%, rgba(226, 101, 80, 0.8) 100%)",
  },
  {
    title: "Eventual Consistency",
    subtitle:
      "REE combines optimistic execution with Bitcoin on-chain state aware roll-back, achieving eventual consistency with Bitcoin.",
    bg: "linear-gradient(159.15deg, #8F3DC9 6.76%, #302064 100%)",
  },
];

const dapps = [
  {
    icon: Convert,
    title: "Bitcoin-Backed Stablecoins",
    subtitle:
      "Issue stablecoins directly on Bitcoin, leveraging its security and liquidity for stable value transfer.",
    bg: "linear-gradient(140.11deg, rgba(143, 61, 201, 0.8) 0%, rgba(226, 101, 80, 0.8) 100%)",
  },
  {
    icon: WalletMoney,
    title: "Synthetic Assets",
    subtitle:
      "Mint programmable Bitcoin-native assets, enabling exposure to various markets without leaving the Bitcoin ecosystem.",
    bg: "linear-gradient(180deg, rgba(143, 61, 201, 0.8) 0%, rgba(101, 32, 139, 0.8) 100%)",
  },
  {
    icon: Chart,
    title: "Decentralized Exchanges",
    subtitle:
      "Enable censorship-resistant trading with Richswap, showcasing the power of Bitcoin-native DeFi.",
    bg: "linear-gradient(140.11deg, rgba(143, 61, 201, 0.8) 0%, rgba(226, 101, 80, 0.8) 100%)",
  },
];
